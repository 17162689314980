import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/main-layout.js"
import Row from "../components/row.js"
import HeaderImage from "../components/elements/header-image.js"
import HTitle from "../components/elements/h-title.js"
import BlockQuote from "../components/elements/block-quote.js"
import PercentageBar from "../components/elements/percentage-bar.js"

const Page = ({ location, data }) => (
  <>
    <SEO title="Skills" location={location} />
    <Layout location={location}>
      <Row xFull hFull noSeparated>
        <HeaderImage image={data.logo.childImageSharp.fluid}>
          <div className="flex w-full h-full items-center justify-center">
            <BlockQuote
              className="max-w-3/4 bg-white-tr-10 p-2"
              quote="Intelligence is the ability to adapt to change."
              footer="Stephen Hawking"
            />
          </div>
        </HeaderImage>
      </Row>

      <Row>
        <HTitle Type="h3">Server skills</HTitle>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
          <PercentageBar percentage="95">
            LAMP (Linux - Apache - mySQL - PHP)
          </PercentageBar>
          <PercentageBar percentage="85">NGINX</PercentageBar>
          <PercentageBar percentage="80">AWS</PercentageBar>
          <PercentageBar percentage="95">CDN</PercentageBar>
          <PercentageBar percentage="85">Varnish</PercentageBar>
          <PercentageBar percentage="95">
            Git Version Control System
          </PercentageBar>
        </div>
      </Row>

      <Row>
        <HTitle Type="h3">Backend skills</HTitle>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
          <PercentageBar percentage="95">PHP</PercentageBar>
          <PercentageBar percentage="95">mySQL</PercentageBar>
          <PercentageBar percentage="90">MVC pattern</PercentageBar>
          <PercentageBar percentage="95">Magento</PercentageBar>
          <PercentageBar percentage="95">CMSs (WordPress, Joomla, etc)</PercentageBar>
          <PercentageBar percentage="85">PHP frameworks (Laravel, CodeIgniter, Yii)</PercentageBar>
        </div>
      </Row>
      
      <Row>
        <HTitle Type="h3">Frontend skills</HTitle>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
          <PercentageBar percentage="95">HTML(5)</PercentageBar>
          <PercentageBar percentage="95">CSS(3)</PercentageBar>
          <PercentageBar percentage="95">CSS frameworks</PercentageBar>
          <PercentageBar percentage="95">Responsive design</PercentageBar>
          <PercentageBar percentage="90">Javascript</PercentageBar>
          <PercentageBar percentage="95">jQuery</PercentageBar>
          <PercentageBar percentage="90">SEO</PercentageBar>
        </div>
      </Row>
      
      <Row>
        <HTitle Type="h3">General skills</HTitle>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
          <PercentageBar percentage="95">Project analyzing</PercentageBar>
          <PercentageBar percentage="90">Problem solving</PercentageBar>
          <PercentageBar percentage="90">Debugging</PercentageBar>
        </div>
      </Row>
    </Layout>
  </>
)

export const query = graphql`
  {
    logo: file(relativePath: { eq: "abstract-3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Page

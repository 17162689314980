import React from "react"

const PercentageBar = ({ children, percentage, color }) => {
  if (!color) {
    switch (true) {
      case percentage >= 90:
        color = "bg-green-600"
        break
      case percentage >= 80:
        color = "bg-blue-600"
        break
      case percentage >= 70:
        color = "bg-yellow-600"
        break
      case percentage >= 60:
        color = "bg-red-600"
        break
      default:
        break
    }
  }
  var percentageStyle = { width: percentage + "%" }

  return (
    <>
      <div className="font-semibold">
        <div>{children}</div>
        <div className="bg-gray-400 overflow-hidden">
          <div
            className={
              "float-left h-8 leading-8 pr-2 w-0 " + color + " text-right"
            }
            style={percentageStyle}
          >
            <span className="text-gray-300">{percentage}%</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default PercentageBar
